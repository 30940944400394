import axiosIns from '@/libs/axios'
import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosIns.get('/documents')
const postAxios = data => axiosIns.post('/documents', data)
const oneAxios = id => axiosIns.get(`/documents/${id}`)
const putAxios = (id, data) => axiosIns.put(`/documents/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/documents/${id}`)
const getDocumentNoAxios = data => axiosWarehouse.post('/documents/documentNo', data)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  getDocumentNoAxios,
}
