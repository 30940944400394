import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/employees')
const postAxios = data => axiosIns.post('/employees', data)
const oneAxios = id => axiosIns.get(`/employees/${id}`)
const putAxios = (id, data) => axiosIns.put(`/employees/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/employees/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
