import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/warehouses')
const postAxios = data => axiosWarehouse.post('/warehouses', data)
const oneAxios = id => axiosWarehouse.get(`/warehouses/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/warehouses/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/warehouses/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
