import axiosAccounting from '@/libs/axios-accounting'

const getAxios = () => axiosAccounting.get('/financialYears')
const postAxios = data => axiosAccounting.post('/financialYears', data)
const oneAxios = id => axiosAccounting.get(`/financialYears/${id}`)
const putAxios = (id, data) => axiosAccounting.put(`/financialYears/${id}`, data)
const deleteAxios = id => axiosAccounting.delete(`/financialYears/${id}`)
const currentYearAxios = () => axiosAccounting.get('/financialYears/currentYear/')

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  currentYearAxios,
}
