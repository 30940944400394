import Vue from 'vue'

// axios
import axios from 'axios'

// const token = '3|cKbkxUBT37VRcrMX6e6xnXh2tWOSzJjSjZUCjCs1'
const axiosCrm = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://crm.growsmart-ss.com/api',
  // baseURL: 'http://127.0.0.1:6600/api',
  timeout: 100000,
  headers: { 'X-Custom-Header': 'foobar' },
})
// axiosCrm.interceptors.request.use(config => {
//   // eslint-disable-next-line no-param-reassign
//   config.headers.Authorization = `Bearer ${token}`
//   return config
// })

Vue.prototype.$http = axiosCrm

export default axiosCrm
