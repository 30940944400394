import axiosCrm from '@/libs/axios-crm'

const getAxios = () => axiosCrm.get('/vendorSets')
const postAxios = data => axiosCrm.post('/vendorSets', data)
const oneAxios = id => axiosCrm.get(`/vendorSets/${id}`)
const searchAxios = id => axiosCrm.get(`/vendorSets/search/${id}`)
const putAxios = (id, data) => axiosCrm.put(`/vendorSets/${id}`, data)
const deleteAxios = id => axiosCrm.delete(`/vendorSets/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
}
